import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { useSelector } from "../../../Redux";
import { ContainerFlex, ContainerMain } from "./NavigationPage_style";
import { UserTypes } from "../../Login/Redux/Reducer/LoginWidget";
// Global
import TopNavigation from "../Components/TopNavigation/TopNavigation";
import NavBar from "../Components/NavBar/NavBar";
import LoginPage from "../../../Modules/Login/Page/LoginPage";
import { useDispatch } from "react-redux";
import * as actions from "../../Login/Redux/Actions";
import * as countriesActions from "../../../Global/Redux/Actions";
import moment from "moment";
// Modules
const DashboardPage = lazy(() => import("../../Dashboard/Page/DashboardPage"));
const BucketsPage = lazy(() => import("../../Buckets/Page/BucketsPage"));
const UsersPage = lazy(() => import("../../Users/Page/UsersPage"));
const InstallerPage = lazy(() => import("../../Installer/Page/InstallerPage"));
const FarmsPage = lazy(() => import("../../Farms/Page/FarmsPage"));
// left navigation
const Navigation = () => {
  let { isAuthenticated: isLoggedIn } = useSelector(
    (state) => state.Login.LoginWidget
  );
  let language = useSelector((state) => state.Countries.Countries.lang);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(actions.autoAuthenticate());
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(countriesActions.setCountries());
    moment.locale(language.toLowerCase());
  }, [language, dispatch]);

  const { userType } = useSelector((state) => state.Login.LoginWidget);

  const routes =
    userType === UserTypes.DEX_MASTER_ADMINISTRATOR ? (
      <Switch>
        <Route path="/dashboard" exact component={DashboardPage} />
        <Route path="/installer" exact component={InstallerPage} />
        <Route path="/farms" exact component={FarmsPage} />
        <Route path="/buckets" exact component={BucketsPage} />
        <Route path="/users" exact component={UsersPage} />

        <Redirect from="/" to="/dashboard" />
      </Switch>
    ) : userType === UserTypes.DEX_ADMINISTRATOR ? (
      <Switch>
        <Route path="/dashboard" exact component={DashboardPage} />
        <Route path="/installer" exact component={InstallerPage} />
        <Route path="/farms" exact component={FarmsPage} />
        <Route path="/users" exact component={UsersPage} />

        <Redirect from="/" to="/dashboard" />
      </Switch>
    ) : (
      <Switch>
        <Route path="/installer" exact component={InstallerPage} />

        <Redirect from="/" to="/installer" />
      </Switch>
    );

  return (
    <Router>
      <Route>
        {/* NavBar visualization */}

        {isLoggedIn && (
          <Route
            path="/"
            render={(props) => <NavBar {...props} userType={userType} />}
          />
        )}

        {/* Routing */}
        <ContainerFlex>
          {isLoggedIn && <TopNavigation />}
          {isLoggedIn ? ( // Login success
            <ContainerMain>
              <Suspense fallback={<></>}>{routes}</Suspense>
            </ContainerMain>
          ) : (
            <>
              <Redirect from="/" to="/login" />
              <Route path="/login" component={LoginPage} />
            </>
          )}
        </ContainerFlex>
      </Route>
    </Router>
  );
};

export default Navigation;
