/**
 * Translations for Buckets menu with the following languages:
 * - English
 * - Portuguese
 *  */

export default {
  //English
  en: {
    BucketsPage: {
      title: "Buckets",
      refresh: "REFRESH",
      ModalComponent: {
        titleMsg: "Add Bucket",
        subtitleMsg: "Fill in the data to create a new bucket.",
        submitButton: "ADD",
        cancelBtn: "CANCEL",
      },
      ShowBucketsBtn: {
        show: "SHOW",
        hide: "HIDE",
        inactive: "INACTIVES",
      },
      addBucketBtn: "ADD BUCKET",
      BucketsTable: {
        TableHead: {
          awsBucket: "AWS Bucket",
          project: "Project",
          lastChange: "Last change",
          status: "Status",
        },
        TableBody: {
          lastChangeFormat: "MM-DD-YYYY HH:mm",
          IsActive: {
            active: "Active",
            inactive: "Inactive",
          },
          changeStatus: "Change status",
        },
        Card: {
          title: "List of Available Buckets",
          NoData: {
            firstLine: "There are no registered Buckets.",
            secondLine: "When buckets are registered, they will appear here.",
          },
          StatusModalComponent: {
            titleMsg: "Do you want to change the status",
            subtitleMsg:
              "You are changing %{bucket} Bucket to %{status}. Do you wish to continue?",
            submitButton: "CHANGE STATUS",
            cancelBtn: "CANCEL",
          },
          EditModalComponent: {
            titleMsg: "Edit Bucket",
            subtitleMsg: "Fill in the data to change the Bucket data.",
            submitButton: "CHANGE",
            cancelBtn: "CANCEL",
          },
          DeleteModalComponent: {
            titleMsg: "Do you really want to delete this Bucket",
            subtitleMsg:
              "Bucket %{bucket} will no longer be available. Do you wish to continue?",
            submitButton: "DELETE",
            cancelBtn: "CANCEL",
          },
        },
      },
      BucketForm: {
        bucketLabel: "AWS Bucket",
        projectLabel: "Project",
        statusLabel: "Status",
        StatusOption: {
          active: "Active",
          inactive: "Inactive",
        },
      },
      Notification: {
        BucketsError: {
          title: "Error",
          message: "Error while getting buckets",
        },
        SavingNewBucketError: {
          title: "Error",
          message: "Error while saving new bucket",
        },
        SavingNewBucketSuccess: {
          title: "Success",
          message: "New bucket saved successfully",
        },
        ChangingBucketError: {
          title: "Error",
          message: "Error while changing bucket",
        },
        ChangingBucketSuccess: {
          title: "Success",
          message: "Bucket changed successfully",
        },
        DeletingBucketError: {
          title: "Error",
          message: "Error while deleting bucket",
        },
        DeletingBucketSuccess: {
          title: "Success",
          message: "Bucket deleted successfully",
        },
      },
    },
  },

  // Portuguese
  pt: {
    BucketsPage: {
      title: "Buckets",
      refresh: "ATUALIZAR",
      ModalComponent: {
        titleMsg: "Adicionar Bucket",
        subtitleMsg: "Preencha os dados para criar e um novo bucket.",
        submitButton: "ADICIONAR",
        cancelBtn: "CANCELAR",
      },
      ShowBucketsBtn: {
        show: "MOSTRAR",
        hide: "OCULTAR",
        inactive: "INATIVOS",
      },
      addBucketBtn: "ADICIONAR BUCKET",
      BucketsTable: {
        TableHead: {
          awsBucket: "Bucket AWS",
          project: "Projeto",
          lastChange: "Última alteração",
          status: "Status",
        },
        TableBody: {
          lastChangeFormat: "DD/MM/YYYY HH:mm",
          IsActive: {
            active: "ATIVO",
            inactive: "INATIVO",
          },
          changeStatus: "Alterar Status",
        },
        Card: {
          title: "Lista de Buckets Disponíveis",
          NoData: {
            firstLine: "Não existem Buckets cadastrados.",
            secondLine: "Quando forem cadastrados, eles aparecerão aqui.",
          },
          StatusModalComponent: {
            titleMsg: "Deseja alterar o status",
            subtitleMsg:
              "Você está alterando o Bucket %{bucket} para %{status}. Deseja continuar?",
            submitButton: "ALTERAR STATUS",
            cancelBtn: "CANCELAR",
          },
          EditModalComponent: {
            titleMsg: "Editar Bucket",
            subtitleMsg: "Preencha os dados para alterar os dados do Bucket.",
            submitButton: "ALTERAR",
            cancelBtn: "CANCELAR",
          },
          DeleteModalComponent: {
            titleMsg: "Você realmente deseja excluir este Bucket?",
            subtitleMsg:
              "O Bucket %{bucket} não estará mais disponível. Você deseja continuar?",
            submitButton: "EXCLUIR",
            cancelBtn: "CANCELAR",
          },
        },
      },
      BucketForm: {
        bucketLabel: "Bucket AWS",
        projectLabel: "Projeto",
        statusLabel: "Status",
        StatusOption: {
          active: "Ativo",
          inactive: "Inativo",
        },
      },
      Notification: {
        BucketsError: {
          title: "Erro",
          message: "Erro ao buscar os Buckets",
        },
        SavingNewBucketError: {
          title: "Erro",
          message: "Erro ao salvar o novo bucket",
        },
        SavingNewBucketSuccess: {
          title: "Sucesso",
          message: "Novo bucket salvo com sucesso",
        },
        ChangingBucketError: {
          title: "Erro",
          message: "Erro ao alterar o bucket",
        },
        ChangingBucketSuccess: {
          title: "Sucesso",
          message: "Bucket alterado com sucesso",
        },
        DeletingBucketError: {
          title: "Erro",
          message: "Erro ao excluir o bucket",
        },
        DeletingBucketSuccess: {
          title: "Sucesso",
          message: "Bucket excluido com sucesso",
        },
      },
    },
  },
};
