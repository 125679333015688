import dashboardTranslations from "./Modules/Dashboard/Translations";
import bucketsTranslations from "./Modules/Buckets/Translations";
import farmsTranslations from "./Modules/Farms/Translations";
import usersTranslations from "./Modules/Users/Translations";
import installerTranslations from "./Modules/Installer/Translations";
import loginTranslations from "./Modules/Login/Translations";
import navigationTranslations from "./Modules/Navigation/Translations";

export default {
  en: {
    ...dashboardTranslations.en,
    ...bucketsTranslations.en,
    ...farmsTranslations.en,
    ...usersTranslations.en,
    ...installerTranslations.en,
    ...loginTranslations.en,
    ...navigationTranslations.en,
  },
  pt: {
    ...dashboardTranslations.pt,
    ...bucketsTranslations.pt,
    ...farmsTranslations.pt,
    ...usersTranslations.pt,
    ...installerTranslations.pt,
    ...loginTranslations.pt,
    ...navigationTranslations.pt,
  },
};
