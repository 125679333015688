import React from "react";
import "moment/locale/pt-br";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import { Provider } from "react-redux";
import store from "./Redux";
import Navigation from "./Modules/Navigation/Page/NavigationPage";

import { Colors } from "./Global/StyleGuide";

// TODO Ask Gilson about dates in web, like, what timezone we should display
// import { LocalizationProvider } from "materialpickersv4";
// import DateFnsUtils from "dateiofnsupdated4picker";

export interface AppProps {}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: Colors.Brand.BrandPrimary,
    },
  },
});

const App: React.FC<AppProps> = () => {
  return (
    <ThemeProvider theme={theme}>
      {/* <LocalizationProvider dateAdapter={DateFnsUtils}> */}
      <Provider store={store}>
        {/* <GlobalTheme> */}
        <ReactNotification />
        <Navigation />
        {/* </GlobalTheme> */}
      </Provider>
      {/* </LocalizationProvider> */}
    </ThemeProvider>
  );
};

export default App;
