import * as React from "react";
import { CircularProgress } from "@material-ui/core";
import { StyleGridLoading } from "./LoginLoading_style";

export interface CircleLoading {
  size?: number;
  thickness?: number;
  color?: "primary" | "secondary" | "inherit";
}
export const modalDOM = document.getElementById("modal");

const Loading: React.FC<CircleLoading> = ({
  size = 120,
  thickness = 2,
  color = "primary",
}) => {
  return (
    <StyleGridLoading>
      <CircularProgress size={size} color={color} thickness={thickness} />
    </StyleGridLoading>
  );
};

export default Loading;
