import { combineReducers } from "redux";
import BucketsList from "./Buckets";
import AwsBuckets from "./AwsBuckets";
import Bucket from "./Bucket";

const BucketsReducers = combineReducers({
  BucketsList,
  AwsBuckets,
  Bucket,
});

export default BucketsReducers;
