import * as actionTypes from "../Types/Countries";

export const setCountries = () => {
  return async (dispacth) => {
    
    let lang = navigator.language || navigator.userLanguage;

    lang = lang.slice(0,2) === 'pt' ? 'pt-BR' : 'en-US';

    const countries = require(`../../../Assets/Countries/${lang}`).default;

    dispacth({
      data: countries,
      lang: lang,
      type: actionTypes.COUNTRIES_SET_COUNTRIES,
    });
  };
};