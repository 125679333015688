import * as actionTypes from "../Types/LoginWidget";

export enum UserTypes {
  DEX_CHAMPION = "DEX_CHAMPION",
  DEX_ADMINISTRATOR = "DEX_ADMINISTRATOR",
  DEX_MASTER_ADMINISTRATOR = "DEX_MASTER_ADMINISTRATOR",
}

export enum ScreensToShow {
  LOGIN_SCREEN = "LOGIN_SCREEN",
  FORGOT_PASSWORD_SCREEN = "FORGOT_PASSWORD_SCREEN",
  CHALLENGE_SCREEN = "CHALLENGE_SCREEN",
}

interface State {
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  userResponseObject?: any;
  hasChallenge?: boolean;
  userType?: UserTypes;
  isWaitingCode?: boolean;
  hasError?: boolean;
  error?: string;
  email: string;
  screenToShow: ScreensToShow;
}

const initialState: State = {
  isAuthenticating: false,
  isAuthenticated: false,
  isWaitingCode: false,
  hasError: false,
  email: "",
  screenToShow: ScreensToShow.LOGIN_SCREEN,
};

const LoginWidget = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.START_AUTO_AUTHENTICATION: {
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: true,
      } as State;
    }
    case actionTypes.START_AUTHENTICATION: {
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: true,
      } as State;
    }
    case actionTypes.SET_AUTHENTICATED: {
      return {
        ...state,
        isAuthenticated: true,
        isAuthenticating: false,
        hasChallenge: false,
        userType: action.userType,
        hasError: false,
        error: "",
      } as State;
    }
    case actionTypes.UNAUTHORIZED_AUTHENTICATION: {
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
        hasError: true,
        error: action.data,
      } as State;
    }
    case actionTypes.UNAUTHORIZED_AUTO_AUTHENTICATION: {
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
        hasError: false,
      } as State;
    }
    case actionTypes.CHALLENGE_COMPLETE_NEW_PASSWORD: {
      return {
        isAuthenticated: false,
        isAuthenticating: false,
        userResponseObject: action.userResponseObject,
        hasChallenge: true,
      } as State;
    }
    case actionTypes.SET_INITIAL_FORGOT_PASSWORD: {
      return { ...state, isWaitingCode: true } as State;
    }
    case actionTypes.SET_USER_EMAIL: {
      return { ...state, email: action.data } as State;
    }
    case actionTypes.SET_FINAL_FORGOT_PASSWORD: {
      return { ...state, isWaitingCode: false } as State;
    }
    case actionTypes.SET_SCREEN_TO_SHOW: {
      return { ...state, screenToShow: action.data } as State;
    }
    case actionTypes.LOGOUT: {
      return { ...initialState, isAuthenticating: false } as State;
    }
    default:
      return state as State;
  }
};

export default LoginWidget;
