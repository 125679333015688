import { combineReducers } from "redux";
import Users from "./Users";
import User from "./User";

const UsersReducers = combineReducers({
  UsersList: Users,
  User: User,
});

export default UsersReducers;
