import * as actionTypes from "../Types/Buckets";

export interface AwsBucketProps {
  name: string;
}

interface State {
  data: AwsBucketProps[];
}

export const initialState: State = {
  data: [],
};

const AwsBuckets = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.AWS_BUCKETS_SET_AWS_BUCKETS:
      return {
        ...state,
        data: action.data,
      } as State;
    default:
      return state;
  }
};

export default AwsBuckets;
