import * as actionTypes from "../Types";
import { BucketProps } from "../../../Buckets/Redux/Reducer/Bucket";

export interface FarmProps {
  id: number;
  farmName: string;
  countryCode: string;
  bucket: BucketProps;
  module: string;
  lastUpload: string;
  lastUploadedFilesQuantity: number;
  installedAppVersion: string;
  activationStatus: string;
  databaseStatus: number;
  csvGenerationStatus: number;
  uploadStatus: number;
  historyFileStatus: number;
  autoUpdateStatus: number;
  lastStatusTimestamp: string;
}

interface State {
  data: FarmProps;
}

export const initialState: State = {
  data: {
    id: 0,
    farmName: "",
    countryCode: "",
    bucket: {
      id: 0,
      awsBucket: "",
      isActive: false,
      lastChange: "",
      project: "",
    },
    module: "",
    lastUpload: "",
    lastUploadedFilesQuantity: 0,
    installedAppVersion: "",
    activationStatus: "",
    autoUpdateStatus: 0,
    csvGenerationStatus: 0,
    databaseStatus: 0,
    historyFileStatus: 0,
    uploadStatus: 0,
    lastStatusTimestamp: "",
  },
};

const Farm = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.FARM_SET_FARM:
      return {
        ...state,
        data: {
          ...action.data,
          bucket: { ...action.data.bucket },
        },
      } as State;
    case actionTypes.FARM_RESET:
      return {
        ...initialState,
      } as State;
    default:
      return state;
  }
};

export default Farm;
