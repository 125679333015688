import * as actionTypes from "../Types";
import { UserProps } from "./Users";

interface State {
  data: UserProps;
}

export const initialState: State = {
  data: {
    email: "",
    name: "",
    userType: "",
    countryCode: "",
    status: "",
  },
};

const User = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.NEW_USER_SET_EMAIL:
      return {
        ...state,
        data: {
          ...state.data,
          email: action.data,
        },
      } as State;
    case actionTypes.NEW_USER_SET_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          name: action.data,
        },
      } as State;
    case actionTypes.NEW_USER_SET_USERTYPE:
      return {
        ...state,
        data: {
          ...state.data,
          userType: action.data,
        },
      } as State;
    case actionTypes.NEW_USER_SET_COUNTRYCODE:
      return {
        ...state,
        data: {
          ...state.data,
          countryCode: action.data,
        },
      } as State;
    case actionTypes.NEW_USER_RESET:
      return {
        ...initialState,
      } as State;
    case actionTypes.USER_SET_USER:
      return {
        ...state,
        data: action.data,
      } as State;
    default:
      return state;
  }
};

export default User;
