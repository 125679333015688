import * as actionTypes from "../Types/";
import { BucketProps } from "./Bucket";

interface NotificationPros {
  title: string;
  message: string;
  type: "success" | "danger" | "info" | "default" | "warning";
  showNotification: boolean;
}
interface State {
  data: BucketProps[];
  isLoading: boolean;
  showAll: boolean;
  notification: NotificationPros;
}

export const initialState: State = {
  data: [],
  isLoading: true,
  showAll: false,
  notification: {
    title: "",
    message: "",
    type: "default",
    showNotification: false,
  },
};

const Buckets = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.BUCKETS_SET_BUCKETS:
      return {
        ...state,
        data: action.data,
      } as State;
    case actionTypes.BUCKETS_SET_LOADER:
      return { ...state, isLoading: action.data } as State;
    case actionTypes.BUCKETS_SET_SHOWALL:
      return { ...state, showAll: !state.showAll } as State;
    case actionTypes.BUCKETS_SET_NOTIFICATION:
      return { ...state, notification: action.data } as State;
    case actionTypes.BUCKETS_RESET_NOTIFICATION:
      return { ...state, notification: initialState.notification } as State;
    default:
      return state;
  }
};

export default Buckets;
