import { t } from "react-i18nify";
export interface MenuTitle {
  icon: string;
  title: string;
  path: string;
}
export function getMenuTitlesForMaster(): Array<MenuTitle> {
  return [
    {
      icon: "dashboard-icon",
      title: t("NavigationPage.NavBar.GetMenuTitles.dashboard"),
      path: "dashboard",
    },
    {
      icon: "installer-icon",
      title: t("NavigationPage.NavBar.GetMenuTitles.installer"),
      path: "installer",
    },
    {
      icon: "farms-icon",
      title: t("NavigationPage.NavBar.GetMenuTitles.farms"),
      path: "farms",
    },
    {
      icon: "buckets-icon",
      title: t("NavigationPage.NavBar.GetMenuTitles.buckets"),
      path: "buckets",
    },
    {
      icon: "users-icon",
      title: t("NavigationPage.NavBar.GetMenuTitles.users"),
      path: "users",
    },
  ];
}

export function getMenuTitlesForAdmin(): Array<MenuTitle> {
  return [
    {
      icon: "dashboard-icon",
      title: t("NavigationPage.NavBar.GetMenuTitles.dashboard"),
      path: "dashboard",
    },
    {
      icon: "installer-icon",
      title: t("NavigationPage.NavBar.GetMenuTitles.installer"),
      path: "installer",
    },
    {
      icon: "farms-icon",
      title: t("NavigationPage.NavBar.GetMenuTitles.farms"),
      path: "farms",
    },
    {
      icon: "users-icon",
      title: t("NavigationPage.NavBar.GetMenuTitles.users"),
      path: "users",
    },
  ];
}

export function getMenuTitlesForChampion(): Array<MenuTitle> {
  return [
    {
      icon: "installer-icon",
      title: t("NavigationPage.NavBar.GetMenuTitles.installer"),
      path: "installer",
    },
  ];
}
