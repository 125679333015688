/**
 * Translations for Farms menu with the following languages:
 * - English
 * - Portuguese
 *  */

export default {
  //English
  en: {
    FarmsPage: {
      title: "Farms",
      refresh: "REFRESH",
      ShowFarmsBtn: {
        show: "SHOW",
        hide: "HIDE",
        inactive: "INACTIVES",
      },
      FarmsTable: {
        TableHead: {
          name: "Name",
          country: "Country",
          project: "Project",
          module: "Module",
          lastUpload: "Last upload",
          files: "Files",
          dexVersion: "DEX version",
          status: "Status",
        },
        TableBody: {
          noBucketProject: "Deleted Bucket",
          lastUploadFormat: "MM-DD-YYYY HH:mm",
          anyUpload: "No upload",
          ActivationStatus: {
            active: "Active",
            inactive: "Inactive",
            pending: "Pendente",
          },
          changeStatus: "Change status",
          Tooltip: {
            activationStatus: "Inactive Farm <br />",
            databaseStatus: "Database error <br />",
            csvGenerationStatus: "CSV generation error <br />",
            uploadStatus: "Upload files error <br />",
            historyFileStatus: "History file error <br />",
            autoUpdateStatus: "Auto update error <br />",
            lastStatusTimestamp: "No connection <br />",
          },
        },
        Card: {
          title: "List of Farms",
          NoData: {
            firstLine: "There are no registered farms.",
            secondLine: "When farms are registered, they will appear here.",
          },
          ModalComponent: {
            titleMsg: "Do you want to change the status",
            subtitleMsg:
              "You are changing %{farm} Farm to %{status}. Do you wish to continue?",
            submitButton: "CHANGE STATUS",
            cancelBtn: "CANCEL",
          },
          DeleteModalComponent: {
            titleMsg: "Do you really want to delete this farm",
            subtitleMsg:
              "Farm %{farm} will no longer be available. Do you wish to continue?",
            submitButton: "DELETE",
            cancelBtn: "CANCEL",
          },
        },
      },
      Notification: {
        GettingFarmsError: {
          title: "Error",
          message: "Error while getting farms",
        },
        UpdatingFarmError: {
          title: "Error",
          message: "Error while updating farms",
        },
        UpdatingFarmSuccess: {
          title: "Success",
          message: "Farm updated successfully",
        },
        DeletingFarmError: {
          title: "Error",
          message: "Error while deleting farm",
        },
        DeletingFarmSuccess: {
          title: "Success",
          message: "Farm deleted successfully",
        },
      },
    },
  },

  // Portuguese
  pt: {
    FarmsPage: {
      title: "Fazendas",
      refresh: "ATUALIZAR",
      ShowFarmsBtn: {
        show: "MOSTRAR",
        hide: "OCULTAR",
        inactive: "INATIVOS",
      },
      FarmsTable: {
        TableHead: {
          name: "Nome",
          country: "País",
          project: "Projeto",
          module: "Módulo",
          lastUpload: "Último upload",
          files: "Arquivos",
          dexVersion: "Versão do DEX",
          status: "Status",
        },
        TableBody: {
          noBucketProject: "Bucket Deletado",
          lastUploadFormat: "DD/MM/YYYY HH:mm",
          anyUpload: "Nenhum upload",
          ActivationStatus: {
            active: "Ativo",
            inactive: "Inativo",
            pending: "Pendente",
          },
          changeStatus: "Alterar Status",
          Tooltip: {
            activationStatus: "Fazenda inativa <br />",
            databaseStatus: "Erro no banco de dados <br />",
            csvGenerationStatus: "Erro na geração do CSV <br />",
            uploadStatus: "Erro no upload de arquivos <br />",
            historyFileStatus: "Erro no arquivo de histórico <br />",
            autoUpdateStatus: "Erro na atualização automática <br />",
            lastStatusTimestamp: "App sem conexão <br />",
          },
        },
        Card: {
          title: "Lista de Fazendas",
          NoData: {
            firstLine: "Não existem fazendas aprovadas.",
            secondLine:
              "Quando existirem fazendas aprovadas, elas aparecerão aqui.",
          },
          ModalComponent: {
            titleMsg: "Deseja alterar o status",
            subtitleMsg:
              "Você está alterando o Fazenda %{farm} para %{status}. Deseja continuar?",
            submitButton: "ALTERAR STATUS",
            cancelBtn: "CANCELAR",
          },
          DeleteModalComponent: {
            titleMsg: "Você realmente deseja excluir esta fazenda?",
            subtitleMsg:
              "A Fazenda %{farm} não estará mais disponível. Você deseja continuar?",
            submitButton: "EXCLUIR",
            cancelBtn: "CANCELAR",
          },
        },
      },
      Notification: {
        GettingFarmsError: {
          title: "Erro",
          message: "Erro ao buscar as fazendas",
        },
        UpdatingFarmError: {
          title: "Erro",
          message: "Erro ao atualizar a fazenda",
        },
        UpdatingFarmSuccess: {
          title: "Sucesso",
          message: "Fazenda atualizada com sucesso",
        },
        DeletingFarmError: {
          title: "Erro",
          message: "Erro ao excluir a fazenda",
        },
        DeletingFarmSuccess: {
          title: "Sucesso",
          message: "Fazenda excluida com sucesso",
        },
      },
    },
  },
};
