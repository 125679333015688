export const START_AUTO_AUTHENTICATION = "START_AUTO_AUTHENTICATION";
export const START_AUTHENTICATION = "START_AUTHENTICATION";
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const UNAUTHORIZED_AUTHENTICATION = "UNAUTHORIZED_AUTHENTICATION";
export const UNAUTHORIZED_AUTO_AUTHENTICATION =
  "UNAUTHORIZED_AUTO_AUTHENTICATION";
export const CHALLENGE_COMPLETE_NEW_PASSWORD =
  "CHALLENGE_COMPLETE_NEW_PASSWORD";
export const LOGOUT = "LOGOUT";
export const SET_INITIAL_FORGOT_PASSWORD = "SET_INITIAL_FORGOT_PASSWORD";
export const SET_FINAL_FORGOT_PASSWORD = "SET_FINAL_FORGOT_PASSWORD";
export const SET_USER_EMAIL = "SET_USER_EMAIL";
export const SET_SCREEN_TO_SHOW = "SET_SCREEN_TO_SHOW";
