import styled from "styled-components";

export const ContainerFlex = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const ContainerMain = styled("div")`
  height: 100vh;
  overflow-y: auto;
`;
