import * as actionTypes from "../Types/Users";

export interface UserProps {
  email: string;
  name: string;
  countryCode: string;
  userType: string;
  status: string;
}
interface NotificationPros {
  title: string;
  message: string;
  type: "success" | "danger" | "info" | "default" | "warning";
  showNotification: boolean;
}

interface State {
  data: UserProps[];
  isLoading: boolean;
  notification: NotificationPros;
}

export const initialState: State = {
  data: [],
  isLoading: true,
  notification: {
    title: "",
    message: "",
    type: "default",
    showNotification: false,
  },
};

const Users = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.USERS_SET_USERS:
      return { ...state, data: action.data } as State;
    case actionTypes.USERS_SET_LOADER:
      return { ...state, isLoading: action.data } as State;
    case actionTypes.USERS_SET_NOTIFICATION:
      return { ...state, notification: action.data } as State;
    case actionTypes.USERS_RESET_NOTIFICATION:
      return {
        ...state,
        notification: { ...initialState.notification },
      } as State;
    default:
      return state;
  }
};

export default Users;
