import styled from "styled-components";
import GreenField from "../../../Assets/Login/greenfield.png";
import CargillLogo from "../../../Assets/Cargill/cargill-logo.svg";
import { Fonts, Colors, MainButtonLarge } from "../../../Global/StyleGuide";

interface MarginTopDivProps {
  value: string;
}

export const LoginWidgetStyle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

export const LeftSide = styled.div`
  width: 50%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

export const RightDivImg = styled.div`
  width: 50%;
  height: auto;
  background: url(${GreenField}) center center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const DexNameSpan = styled.span`
  ${Fonts.Titles.H1}
  color: ${Colors.Brand.BrandPrimary};
`;

export const PoweredBy = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2vh;
`;

export const PoweredBySpan = styled.span`
  ${Fonts.Body.B2}
  color: ${Colors.Neutral.StandardGray};
`;

export const CargillLogoImg = styled.div`
  margin-top: 2vh;
  width: 56px;
  height: 24px;
  background: url(${CargillLogo}) center center;
  background-size: initial;
  background-repeat: no-repeat;
`;

export const FormLogin = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  margin-top: 5vh;
`;

export const FormHeader = styled.span`
  ${Fonts.Subtitles.S1}
  color: ${Colors.Neutral.PureBlack};
`;

export const MarginTopDiv = styled("div")<MarginTopDivProps>`
  margin-top: ${({ value }) => value};
`;

export const LoginActionBtn = styled(MainButtonLarge)`
  width: 316px;
`;
