/**
 * Translations for Users menu with the following languages:
 * - English
 * - Portuguese
 *  */

export default {
  //English
  en: {
    UsersPage: {
      title: "Users",
      refresh: "REFRESH",
      addUserBtn: "ADD USER",
      ModalComponent: {
        titleMsg: "Add user",
        subtitleMsg: "Fill in the data to create a new user.",
        submitButton: "ADD",
        cancelBtn: "CANCEL",
      },
      UsersTable: {
        TableHead: {
          type: "Type",
          name: "Name",
          email: "E-mail",
          country: "Country",
        },
        TableBody: {
          resetPassword: "Reset password",
        },
        Card: {
          title: "List of Active Users",
          NoData: {
            firstLine: "There are no registered users.",
            secondLine: "When users are registered, they will appear here.",
            midLine: 'Click on the "Add Users" button to register a new user.',
          },
          ModalComponent: {
            TitleMsg: {
              editUser: "Edit user",
              deleteUser: "Do you want to delete the user?",
            },
            SubtitleMsg: {
              editUser: "Change the data to edit the user.",
              deleteUser:
                "You are deleting the user %{user}. Do you want to continue?",
            },
            SubmitButton: {
              editUser: "EDIT",
              deleteUser: "DELETE",
            },
            cancelBtn: "CANCEL",
          },
        },
      },
      UserForm: {
        typeLabel: "Type",
        TypeOptions: {
          champion: "Champion",
          administrator: "Administrator",
          generalAdminitrator: "General-Administrator",
        },
        nameLabel: "Name",
        emailLabel: "E-mail",
        countryLabel: "Country",
      },
      Notification: {
        GettingUsersError: {
          title: "Error",
          message: "Error while getting users",
        },
        CreatingUserError: {
          title: "Error",
          message: "Error while creating the user",
        },
        CreatingUserSuccess: {
          title: "Success",
          message: "User created successfully",
        },
        UpdatingUserError: {
          title: "Error",
          message: "Error while updating the user",
        },
        UpdatingUserSuccess: {
          title: "Success",
          message: "User updated successfully",
        },
        DeletingUserError: {
          title: "Error",
          message: "Error while deleting the user",
        },
        DeletingUserSuccess: {
          title: "Success",
          message: "User deleted successfully",
        },
        ResetingPasswordUserError: {
          title: "Error",
          message: "Error while reseting user password",
        },
        ResetingPasswordUserSuccess: {
          title: "Success",
          message: "User password reseted successfully",
        },
      },
    },
  },

  // Portuguese
  pt: {
    UsersPage: {
      title: "Usuários",
      refresh: "ATUALIZAR",
      addUserBtn: "ADICIONAR USUÁRIO",
      ModalComponent: {
        titleMsg: "Adicionar Usuário",
        subtitleMsg: "Preencha os dados para criar um novo usuário.",
        submitButton: "ADICIONAR",
        cancelBtn: "CANCELAR",
      },
      UsersTable: {
        TableHead: {
          type: "Tipo",
          name: "Nome",
          email: "E-mail",
          country: "País",
        },
        TableBody: {
          resetPassword: "Resetar senha",
        },
        Card: {
          title: "Lista de Usuários",
          NoData: {
            firstLine: "Não existem usuários cadastrados.",
            secondLine:
              "Quando existirem usuarios cadastrados, eles aparecerão aqui.",
            midLine:
              'Clique no botão "Adicionar Usuários" para cadastrar um novo usuário.',
          },
          ModalComponent: {
            TitleMsg: {
              editUser: "Editar usuário",
              deleteUser: "Deseja deletar o usuário?",
            },
            SubtitleMsg: {
              editUser: "Altere os dados para editar o usuário.",
              deleteUser:
                "Você está deletando o usuário %{user}. Deseja continuar?",
            },
            SubmitButton: {
              editUser: "EDITAR",
              deleteUser: "EXCLUIR",
            },
            cancelBtn: "CANCELAR",
          },
        },
      },
      UserForm: {
        typeLabel: "Tipo",
        TypeOptions: {
          champion: "Champion",
          administrator: "Administrador",
          generalAdminitrator: "Administrador-Geral",
        },
        nameLabel: "Nome",
        emailLabel: "E-mail",
        countryLabel: "País",
      },
      Notification: {
        GettingUsersError: {
          title: "Erro",
          message: "Erro ao buscar os usuários",
        },
        CreatingUserError: {
          title: "Erro",
          message: "Erro ao criar o usuário",
        },
        CreatingUserSuccess: {
          title: "Sucesso",
          message: "Usuário criado com sucesso",
        },
        UpdatingUserError: {
          title: "Erro",
          message: "Erro ao alterar o usuário",
        },
        UpdatingUserSuccess: {
          title: "Sucesso",
          message: "Usuário alterado com sucesso",
        },
        DeletingUserError: {
          title: "Erro",
          message: "Erro ao excluir o usuário",
        },
        DeletingUserSuccess: {
          title: "Sucesso",
          message: "Usuário excluído com sucesso",
        },
        ResetingPasswordUserError: {
          title: "Erro",
          message: "Erro ao resetar a senha do usuário",
        },
        ResetingPasswordUserSuccess: {
          title: "Success",
          message: "Senha do usuário resetada com sucesso",
        },
      },
    },
  },
};
