import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import {
  useSelector as useReduxSelector,
  TypedUseSelectorHook,
} from "react-redux";
import thunk from "redux-thunk";

// Reducers
import LoginReducer from "./Modules/Login/Redux/Reducer";
import DashboardReducer from "./Modules/Dashboard/Redux/Reducer";
import BucketsReducer from "./Modules/Buckets/Redux/Reducer";
import UsersReducer from "./Modules/Users/Redux/Reducer";
import CountriesReducer from "./Global/Redux/Reducer";
import FarmsReducer from "./Modules/Farms/Redux/Reducer";

const rootReducer = combineReducers({
  Dashboard: DashboardReducer,
  Login: LoginReducer,
  Buckets: BucketsReducer,
  Users: UsersReducer,
  Countries: CountriesReducer,
  Farms: FarmsReducer,
});

// The following declaration is used to compose with DevTools only on DESKTOP Web Browsers.
const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
export type RootState = ReturnType<typeof rootReducer>;
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export default store;
