import * as actionTypes from "../Types";
import { BucketProps } from "../../../Buckets/Redux/Reducer/Bucket";

interface FarmProps {
  id: number;
  farmName: string;
  countryCode: string;
  bucket: BucketProps;
  module: string;
  installedBy: string;
  installDate: string;
}
interface NotificationPros {
  title: string;
  message: string;
  type: "success" | "danger" | "info" | "default" | "warning";
  showNotification: boolean;
}

interface State {
  data: FarmProps[];
  isLoading: boolean;
  notification: NotificationPros;
}

export const initialState: State = {
  data: [],
  isLoading: true,
  notification: {
    title: "",
    message: "",
    type: "default",
    showNotification: false,
  },
};

const PendingFarms = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.DASHBOARD_SET_PENDING_FARMS_DATA:
      return { ...state, data: action.data } as State;
    case actionTypes.DASHBOARD_SET_LOADER:
      return { ...state, isLoading: action.data } as State;
    case actionTypes.DASHBOARD_SET_NOTIFICATION:
      return { ...state, notification: action.data } as State;
    case actionTypes.DASHBOARD_RESET_NOTIFICATION:
      return { ...state, notification: initialState.notification } as State;
    default:
      return state;
  }
};

export default PendingFarms;
