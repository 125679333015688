export const USERS_SET_USERS = "USERS_SET_USERS";
export const NEW_USER_SET_EMAIL = "NEW_USER_SET_EMAIL";
export const NEW_USER_SET_NAME = "NEW_USER_SET_NAME";
export const NEW_USER_SET_STATUS = "NEW_USER_SET_STATUS";
export const NEW_USER_SET_USERTYPE = "NEW_USER_SET_USERTYPE";
export const NEW_USER_SET_COUNTRYCODE = "NEW_USER_SET_COUNTRYCODE";
export const NEW_USER_RESET = "NEW_USER_RESET";
export const NEW_USER_ADD_USER = "NEW_USER_ADD_USER";
export const USERS_SET_LOADER = "USERS_SET_LOADER";
export const USER_SET_USER = "USER_SET_USER";
export const USERS_SET_NOTIFICATION = "USERS_SET_NOTIFICATION";
export const USERS_RESET_NOTIFICATION = "USERS_RESET_NOTIFICATION";
