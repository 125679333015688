import React from "react";
import * as H from "history";
import { StyledMenuButton } from "./MenuButton_style";

interface MenuButtonProps {
  history: H.History;
  title: string;
  pathTo: string;
  location: Location;
  icon: string;
}

const MenuButton = (props: MenuButtonProps) => {
  const pathMatches = props.location.pathname === props.pathTo;
  return (
    <StyledMenuButton to={props.pathTo}>
      <img src={props.icon} alt={props.title} width="22px" height="22px" />
      <span>{props.title}</span>
      {pathMatches && <div />}
    </StyledMenuButton>
  );
};

export default MenuButton;
