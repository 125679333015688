import * as actionTypes from "../Types";

export interface BucketProps {
  id: number;
  awsBucket: string;
  project: string;
  lastChange: string;
  isActive: boolean;
}

interface State {
  data: BucketProps;
}

export const initialState: State = {
  data: {
    id: 0,
    lastChange: "",
    awsBucket: "",
    project: "",
    isActive: true,
  },
};

const Bucket = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.NEW_BUCKET_SET_AWSBUCKET:
      return {
        ...state,
        data: {
          ...state.data,
          awsBucket: action.data,
        },
      } as State;
    case actionTypes.NEW_BUCKET_SET_PROJECT:
      return {
        ...state,
        data: {
          ...state.data,
          project: action.data,
        },
      } as State;
    case actionTypes.NEW_BUCKET_SET_STATUS:
      return {
        ...state,
        data: {
          ...state.data,
          isActive: action.data,
        },
      } as State;
    case actionTypes.NEW_BUCKET_RESET:
    case actionTypes.BUCKET_RESET:
      return {
        ...initialState,
      } as State;
    case actionTypes.BUCKET_SET_BUCKET:
      return {
        ...state,
        data: action.data,
      } as State;
    default:
      return state;
  }
};

export default Bucket;
