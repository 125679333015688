import { css } from "styled-components";
import { Fonts, GraphicElements, Colors } from "./index";
const { Body, Subtitles, Captions } = Fonts;
const { BorderRadius, BorderWidthStyle } = GraphicElements;

export const input = css`
  position: absolute;
  height: 40px;
  width: 100%;
  outline: none;
  color: ${Colors.Neutral.DarkGray};
  ${Body.B2}
  ${BorderRadius.XLarge}
  ${BorderWidthStyle.SolidSmall}  
  border-color: ${({ type }) =>
    type === "error"
      ? Colors.Status.FailureStatus
      : Colors.Neutral.StandardGray};
  background: ${({ disabled }) =>
    disabled ? Colors.Neutral.LightGray : Colors.Neutral.PureWhite};
  padding: 10px 20px;
  bottom: 0;

  &:focus {
    border-color: ${({ type }) =>
      type === "error"
        ? Colors.Status.FailureStatus
        : Colors.Brand.BrandPrimary};
    color: ${Colors.Neutral.DarkGray};
  }

  :placeholder-shown + label {
    cursor: text;
    transform: translate(0, 2.3rem) scale(1);
    top: ${({ modal }) => (modal ? "-25px" : "0")};
    padding: ${({ modal }) =>
      modal ? "20px !important" : "0 20px !important"};
    ${Subtitles.S2}
    color: ${({ disabled }) =>
      disabled ? Colors.Neutral.DarkGray : Colors.Neutral.StandardGray};
  }

  ::-webkit-input-placeholder {
    margin-bottom: 0px;
    opacity: 0;
  }
`;
export const label = css`
  transition: all 0.2s;
  position: relative;
  top: ${({ modal }) => (modal ? "-8px" : "-5px")};
  padding: 8px 0 !important;
  ${Captions.CP}
`;
