import styled from "styled-components";
import { Colors, Fonts } from "../../../../Global/StyleGuide";

export const StyledNavBar = styled.div`
  background-color: ${Colors.Neutral.PureBlack};
  height: 100%;
  width: 234px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
`;

export const NavBarTitle = styled.span`
  ${Fonts.Titles.H6}
  color: ${Colors.Neutral.PureWhite};
  margin-top: 21px;
  margin-bottom: 55px;
`;

export const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const TopPart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BottomPart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 80px 72px 80px;
  cursor: pointer;

  span {
    ${Fonts.Body.B2}
    color: ${Colors.Neutral.PureWhite};
    margin-left: 16px;
  }
`;
