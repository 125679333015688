/**
 * Translations for Installer menu with the following languages:
 * - English
 * - Portuguese
 *  */

export default {
  //English
  en: {
    InstallerPage: {
      title: "Installer",
      InstallerCard: {
        versionLabel: "Last version:",
        buttonLabel: "DOWNLOAD INSTALLER",
      },
      Notification: {
        GettingVersionError: {
          title: "Error",
          message: "Error while getting current installer version",
        },
        DownloadInstallerSuccess: {
          title: "Success",
          message: "Installer downloaded successfully",
        },
        DownloadInstallerError: {
          title: "Error",
          message: "Error while downloading installer",
        },
      },
    },
  },

  // Portuguese
  pt: {
    InstallerPage: {
      title: "Instalador",
      InstallerCard: {
        versionLabel: "Última versão:",
        buttonLabel: "BAIXAR INSTALADOR",
      },
      Notification: {
        GettingVersionError: {
          title: "Erro",
          message: "Erro ao buscar a versão atual do instalador",
        },
        DownloadInstallerSuccess: {
          title: "Sucesso",
          message: "Sucesso ao fazer o download do instalador",
        },
        DownloadInstallerError: {
          title: "Erro",
          message: "Erro ao fazer o download do instalador",
        },
      },
    },
  },
};
