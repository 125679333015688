export const Colors = {
  Brand: {
    BrandPrimary: '#5BB6A0',
    BrandBluePrimary: '#2D9BD7',
  },
  Status: {
    SuccessStatus: '#5ACC63',
    WaitingStatus: '#F7D04E',
    FailureStatus: '#F55A5A',
    SystemMessageStatus: '#4686DE',
  },
  Neutral: {
    PureBlack: '#38484F',
    DarkGray: '#707070',
    StandardGray: '#C2CBCF',
    LightGray: '#EEEEEE',
    PureWhite: '#FCFCFC',
  },
  Shadows: {
    PureBlack: '#38484F40',
  },
};
