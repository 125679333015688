import { combineReducers } from "redux";
import FarmsList from "./Farms";
import SelectedFarm from "./Farm";

const FarmsReducers = combineReducers({
  FarmsList,
  SelectedFarm,
});

export default FarmsReducers;
