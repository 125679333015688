import * as actionTypes from "../Types/LoginWidget";
import Auth from "@aws-amplify/auth";
import { ScreensToShow } from "../Reducer/LoginWidget";

const getLoggedUserType = () => {
  return Auth.currentSession().then((user) => {
    return user.getIdToken().decodePayload()["custom:userType"];
  });
};

export const authenticate = (email, password) => {
  return async (dispatch, getState) => {
    try {
      const { error } = getState().Login.LoginWidget;
      if (
        error !== "ExpiredCodeException" &&
        error !== "LimitExceededException" &&
        error !== "CodeMismatchException"
      ) {
        dispatch({
          type: actionTypes.START_AUTHENTICATION,
        });
        const userObject = await Auth.signIn(email, password);
        if (userObject.challengeName === "NEW_PASSWORD_REQUIRED") {
          dispatch({
            type: actionTypes.CHALLENGE_COMPLETE_NEW_PASSWORD,
            userResponseObject: userObject,
          });
          dispatch({
            data: ScreensToShow.CHALLENGE_SCREEN,
            type: actionTypes.SET_SCREEN_TO_SHOW,
          });
        } else {
          const userType = await getLoggedUserType();
          if (userType)
            dispatch({
              type: actionTypes.SET_AUTHENTICATED,
              userType: userType,
            });
        }
      }
    } catch (err) {
      dispatch({
        data: err.code,
        type: actionTypes.UNAUTHORIZED_AUTHENTICATION,
      });
      if (
        err.code === "UserNotFoundException" ||
        err.code === "NotAuthorizedException"
      ) {
        dispatch({
          data: ScreensToShow.LOGIN_SCREEN,
          type: actionTypes.SET_SCREEN_TO_SHOW,
        });
      } else {
        dispatch({
          data: ScreensToShow.FORGOT_PASSWORD_SCREEN,
          type: actionTypes.SET_SCREEN_TO_SHOW,
        });
      }
    }
  };
};

export const completeNewPassword = (userResponseObject, newPassword) => {
  return async (dispatch) => {
    try {
      const completed = await Auth.completeNewPassword(
        userResponseObject,
        newPassword,
        {
          name: userResponseObject.challengeParam.userAttributes.name,
        }
      );
      if (completed) {
        const userType = await getLoggedUserType();
        dispatch({ type: actionTypes.SET_AUTHENTICATED, userType: userType });
      }
    } catch (err) {
      dispatch({
        data: err.code,
        type: actionTypes.UNAUTHORIZED_AUTHENTICATION,
      });
      dispatch({
        data: ScreensToShow.FORGOT_PASSWORD_SCREEN,
        type: actionTypes.SET_SCREEN_TO_SHOW,
      });
    }
  };
};

export const autoAuthenticate = () => {
  return async (dispatch) => {
    try {
      const user = await Auth.currentSession();
      let userType = undefined;
      if (user) userType = await getLoggedUserType();
      if (user && userType)
        dispatch({
          type: actionTypes.SET_AUTHENTICATED,
          userType: userType,
        });
    } catch (err) {
      dispatch({
        data: err.code,
        type: actionTypes.UNAUTHORIZED_AUTO_AUTHENTICATION,
      });
      dispatch({
        data: ScreensToShow.LOGIN_SCREEN,
        type: actionTypes.SET_SCREEN_TO_SHOW,
      });
    }
  };
};

export const initialResetPassword = (userMail) => {
  return async (dispatch) => {
    try {
      await Auth.forgotPassword(userMail);

      dispatch({
        type: actionTypes.SET_INITIAL_FORGOT_PASSWORD,
      });
    } catch (err) {
      dispatch({
        data: err.code,
        type: actionTypes.UNAUTHORIZED_AUTHENTICATION,
      });
    }
  };
};

export const finalResetPassword = (userMail, code, new_password) => {
  return async (dispatch) => {
    try {
      await Auth.forgotPasswordSubmit(userMail, code, new_password).then(
        (data) => {
          dispatch({
            type: actionTypes.SET_FINAL_FORGOT_PASSWORD,
          });
        }
      );
    } catch (err) {
      dispatch({
        data: err.code,
        type: actionTypes.UNAUTHORIZED_AUTHENTICATION,
      });
    }
  };
};

export const logout = () => {
  return (dispatch) => {
    Auth.signOut();
    dispatch({ type: actionTypes.LOGOUT });
  };
};

export const setEmail = (email) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_USER_EMAIL, data: email });
  };
};

export const setScreenToShow = (screen) => {
  return (dispatch) => {
    dispatch({
      data: screen,
      type: actionTypes.SET_SCREEN_TO_SHOW,
    });
  };
};
