import * as React from "react";
import { StyledTopNavigation, Logo } from "./TopNavigation_style";
import Cargill from "../../../../Assets/Cargill/cargill-logo.svg";

export interface TopNavigationProps {}

const TopNavigation: React.FC<TopNavigationProps> = () => {
  return (
    <StyledTopNavigation>
      <Logo src={Cargill} alt="Cargill" />
    </StyledTopNavigation>
  );
};

export default TopNavigation;
