import styled from "styled-components";
import { Colors, GraphicElements } from "../../../../Global/StyleGuide";

export const StyledTopNavigation = styled.div`
  width: calc(100% - 234px);
  height: 64px;
  background-color: ${Colors.Neutral.PureWhite};
  ${GraphicElements.Shadow.Strong}
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: flex-start;
`;

export const Logo = styled.img`
  margin: 20px 25px;
`;
