import * as React from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../../Login/Redux/Actions/index";
import {
  StyledNavBar,
  NavBarTitle,
  MenuItems,
  TopPart,
  BottomPart,
} from "./NavBar_style";
import {
  getMenuTitlesForAdmin,
  getMenuTitlesForChampion,
  getMenuTitlesForMaster,
} from "../../Utils/getMenuTitles";
import { UserTypes } from "../../../Login/Redux/Reducer/LoginWidget";
import MenuButton from "./MenuButton/MenuButton";
import LogoutIcon from "../../../../Assets/Icons/logout-icon.svg";
import { t } from "react-i18nify";

export interface NavBarProps {
  userType: UserTypes;
}

const NavBar = (props: any) => {
  const dispatch = useDispatch();

  // Generate Menu Navigation Buttons dynamically
  const navButtons =
    props.userType === UserTypes.DEX_MASTER_ADMINISTRATOR
      ? getMenuTitlesForMaster()
      : props.userType === UserTypes.DEX_ADMINISTRATOR
      ? getMenuTitlesForAdmin()
      : getMenuTitlesForChampion();

  const sharedProps = {
    history: props.history,
    location: props.location,
  };

  return (
    <StyledNavBar>
      <TopPart>
        <NavBarTitle>DEX</NavBarTitle>
        <MenuItems>
          {navButtons.map((button: any) => {
            return (
              button && (
                <MenuButton
                  key={button.title}
                  {...sharedProps}
                  title={button.title}
                  pathTo={"/" + button.path}
                  icon={require("../../../../Assets/Icons/" +
                    button.icon +
                    ".svg")}
                ></MenuButton>
              )
            );
          })}
        </MenuItems>
      </TopPart>
      <BottomPart onClick={() => dispatch(logout())}>
        <img src={LogoutIcon} alt="Logout" width="14px" height="14px" />
        <span>{t("NavigationPage.NavBar.logout")}</span>
      </BottomPart>
    </StyledNavBar>
  );
};

export default NavBar;
