import styled from "styled-components";
import { Fonts, GraphicElements, Colors } from "./index";
import EyeDisabled from "../../Assets/Icons/eye-disabled.svg";
import EyeEnabled from "../../Assets/Icons/eye-enabled.svg";
import ArrowDown from "../../Assets/Icons/select-arrow-down-disabled.svg";
import ArrowUp from "../../Assets/Icons/select-arrow-up-enabled.svg";
import { input, label } from "./input_css";
const { BorderRadius, Shadow } = GraphicElements;

interface InputGroupProps {
  modal?: boolean;
  width?: string;
  noMargin?: boolean;
  marginTop?: boolean;
}

interface ClickableEyeIconProps {
  show?: boolean;
  disabled?: string;
}

interface ClickableSelectIconProps {
  closed: boolean;
  disabled?: string;
}

interface InputProps {
  select?: boolean;
}

export const InputGroup = styled("div")<InputGroupProps>`
  display: flex;
  height: ${({ modal }) => (modal ? "62px" : "65px")};
  width: ${({ width }) => (width ? width : "382px")};
  flex-flow: column-reverse;
  ${({ noMargin }) => (noMargin ? "" : "margin-bottom: 1em")};
  position: relative;
  justify-content: flex-end;
  ${({ marginTop }) => marginTop && "margin-top: 18px;"}
`;

export const Input = styled.input<InputProps>`
  ${input};
  ${({ select }) => select && "caret-color: transparent;"}
  ${({ select }) => select && "cursor: default;"}
`;

interface LabelProps {
  modal?: boolean;
}
export const Label = styled("label")<LabelProps>`
  ${label};
`;

export const ClickableEyeIcon = styled("div")<ClickableEyeIconProps>`
  position: absolute;
  height: 17px;
  width: 22px;
  right: 21px;
  transition: all 0.2s;
  top: 36px;
  background: url(${({ show }) => (show ? EyeDisabled : EyeEnabled)}) top center;
  background-repeat: no-repeat;
  cursor: ${({ disabled }) => (disabled === "disabled" ? "" : "pointer")};
  pointer-events: ${({ disabled }) => (disabled === "disabled" ? "none" : "")};
`;

export const ListDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.Neutral.PureWhite};
  ${Shadow.Strong};
  color: ${Colors.Neutral.DarkGray};
  ${BorderRadius.Medium}
  overflow-y: scroll;

  padding: 8px 0;
  position: absolute;
  width: 100%;
  max-height: 250px;
  top: 70px;
  z-index: 3;
`;

export const ListItem = styled.div`
  background-color: ${Colors.Neutral.PureWhite};
  ${Fonts.Body.B2}
  padding: 8px 12px;

  &:hover {
    color: ${Colors.Neutral.PureBlack};
    background-color: ${Colors.Neutral.LightGray};
  }
`;

export const InputTextError = styled.span`
  position: absolute;
  top: 70px;
  right: 0;
  color: ${Colors.Status.FailureStatus};
  ${Fonts.Body.B2}
`;

export const ClickableSelectIcon = styled("div")<ClickableSelectIconProps>`
  position: absolute;
  height: 6px;
  width: 11px;
  right: 27px;
  transition: all 0.2s;
  top: ${({ closed }) => (closed ? "40px" : "39px")};
  background: url(${({ closed }) => (closed ? ArrowDown : ArrowUp)}) top center;
  background-repeat: no-repeat;
  cursor: ${({ disabled }) => (disabled === "disabled" ? "" : "pointer")};
  pointer-events: ${({ disabled }) => (disabled === "disabled" ? "none" : "")};
`;
