import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../Redux/Actions";
import { useSelector } from "../../../../Redux";

import {
  FormLogin,
  FormHeader,
  MarginTopDiv,
  LoginActionBtn as UpdatePasswordBtn,
} from "../../Page/LoginPage_style";

import {
  Input,
  Label,
  InputGroup,
  InputTextError,
  ClickableEyeIcon,
  LinkStyle as BackToLogin,
} from "../../../../Global/StyleGuide";
import { verifyPassword } from "../../../../Utils";
import { t } from "react-i18nify";

interface ChallengeComponentProps {}

const ChallengeComponent: React.FC<ChallengeComponentProps> = () => {
  const dispatch = useDispatch();
  const { userResponseObject } = useSelector(
    (state) => state.Login.LoginWidget
  );
  const [readOnly, setReadOnly] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [completePassword, setCompletePassword] = useState("");
  const [confirmCompletePassword, setConfirmCompletePassword] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      setReadOnly(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const handleCompleteChallenge = async (event: any) => {
    event.preventDefault();
    dispatch(actions.completeNewPassword(userResponseObject, completePassword));
  };

  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handlePasswordValidation = (event: any) => {
    const currentPassword = event.target.value;
    if (verifyPassword(currentPassword)) {
      setErrorPassword("");

      if (confirmCompletePassword) {
        handlePasswordMatch(currentPassword, confirmCompletePassword);
      }
    } else {
      setErrorPassword(t("LoginPage.ChallengeComponent.invalidPassword"));
    }
  };

  const handlePasswordMatch = (password: string, confirmPassword: string) => {
    if (confirmPassword.length < password.length) {
      return;
    }
    if (confirmPassword !== "" && password !== confirmPassword) {
      setErrorConfirmPassword(
        t("LoginPage.ChallengeComponent.notEqualPassword")
      );
    } else {
      setErrorConfirmPassword("");
    }
  };

  return (
    <FormLogin onSubmit={handleCompleteChallenge}>
      <FormHeader>
        {t("LoginPage.ChallengeComponent.chooseNewPassword")}
      </FormHeader>
      <MarginTopDiv value="3vh">
        <InputGroup width="316px" noMargin>
          <Input
            id="newPass"
            readOnly={readOnly}
            name="newPass"
            placeholder={t("LoginPage.ChallengeComponent.newPassword")}
            value={completePassword}
            onChange={(e: any) => {
              setCompletePassword(e.target.value);
              if (confirmCompletePassword) {
                handlePasswordValidation(e);
              }
            }}
            onBlur={handlePasswordValidation}
            type={showPassword ? "text" : "password"}
          />
          <Label htmlFor="newPass">
            {t("LoginPage.ChallengeComponent.newPassword")}
          </Label>
          <ClickableEyeIcon show={!showPassword} onClick={toggleShowPassword} />
          <InputTextError>{errorPassword}</InputTextError>
        </InputGroup>
      </MarginTopDiv>
      <MarginTopDiv value="3vh">
        <InputGroup width="316px" noMargin>
          <Input
            id="confirmNewPass"
            readOnly={readOnly}
            name="confirmNewPass"
            placeholder={t("LoginPage.ChallengeComponent.confirmNewPassword")}
            value={confirmCompletePassword}
            onChange={(e: any) => {
              handlePasswordMatch(completePassword, e.target.value);
              setConfirmCompletePassword(e.target.value);
            }}
            onBlur={() =>
              handlePasswordMatch(completePassword, confirmCompletePassword)
            }
            type={showConfirmPassword ? "text" : "password"}
          />
          <Label htmlFor="confirmNewPass">
            {t("LoginPage.ChallengeComponent.confirmNewPassword")}
          </Label>
          <ClickableEyeIcon
            show={!showConfirmPassword}
            onClick={toggleShowConfirmPassword}
          />
          <InputTextError>{errorConfirmPassword}</InputTextError>
        </InputGroup>
      </MarginTopDiv>
      <MarginTopDiv value="6vh">
        <UpdatePasswordBtn
          type="submit"
          buttonStyle="filled"
          disabled={
            completePassword === "" ||
            confirmCompletePassword === "" ||
            completePassword !== confirmCompletePassword ||
            completePassword.length < 8 ||
            confirmCompletePassword.length < 8 ||
            !verifyPassword(completePassword) ||
            !verifyPassword(confirmCompletePassword)
          }
        >
          {t("LoginPage.ChallengeComponent.updatePasswordAndLogin")}
        </UpdatePasswordBtn>
      </MarginTopDiv>
      <MarginTopDiv value="6vh">
        <BackToLogin to="/login">
          {t("LoginPage.ChallengeComponent.backToLoginPage")}
        </BackToLogin>
      </MarginTopDiv>
    </FormLogin>
  );
};

export default ChallengeComponent;
