import { Grid } from "@material-ui/core";
import styled from "styled-components";

export const StyleGridLoading = styled(Grid)`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 100;
  cursor: wait;
  width: 50%;
  height: 100vh;
  overflow: auto;
`;
