import React from "react";
import { useDispatch } from "react-redux";
import * as actions from "../Redux/Actions";
import { useSelector } from "../../../Redux";
import { ScreensToShow } from "../Redux/Reducer/LoginWidget";
import {
  LoginWidgetStyle,
  LeftSide,
  RightDivImg,
  DexNameSpan,
  PoweredBy,
  PoweredBySpan,
  CargillLogoImg,
} from "./LoginPage_style";

import LoginComponent from "../Components/LoginComponent/LoginComponent";
import ChallengeComponent from "../Components/ChallengeComponent/ChallengeComponent";
import ForgotPasswordComponent from "../Components/ForgotPasswordComponent/ForgotPasswordComponent";

export interface LoginWidgetProps {}

const Login: React.FC<LoginWidgetProps> = () => {
  let { screenToShow } = useSelector((state) => state.Login.LoginWidget);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(actions.autoAuthenticate());
  }, [dispatch]);

  return (
    <LoginWidgetStyle>
      <LeftSide>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DexNameSpan>DEX</DexNameSpan>
          <PoweredBy>
            <PoweredBySpan>powered by</PoweredBySpan>
            <CargillLogoImg />
          </PoweredBy>
          {screenToShow === ScreensToShow.CHALLENGE_SCREEN ? (
            <ChallengeComponent />
          ) : screenToShow === ScreensToShow.FORGOT_PASSWORD_SCREEN ? (
            <ForgotPasswordComponent />
          ) : (
            <LoginComponent />
          )}
        </div>
        <div style={{ height: "21vh", width: "1px" }}></div>
      </LeftSide>
      <RightDivImg />
    </LoginWidgetStyle>
  );
};

export default Login;
