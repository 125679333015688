import styled from "styled-components";
import { Colors, Fonts } from "../../../../../Global/StyleGuide";
import { Link } from "react-router-dom";

export const StyledMenuButton = styled(Link)`
  margin: 5px 0px;
  padding: 0px 24px;
  width: 100%;
  height: 48px;
  text-align: left;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: initial;

  &:focus {
    outline: none;
  }

  &:link,
  &:visited,
  &:hover,
  &:active {
    filter: brightness(1.2);
    color: ${Colors.Neutral.PureBlack};
  }

  span {
    ${Fonts.Subtitles.S1}
    padding-left: 32px;
    color: ${Colors.Neutral.PureWhite};
  }

  div {
    width: 4px;
    height: 48px;
    background-color: ${Colors.Neutral.PureWhite};
    position: absolute;
    right: 0;
    border-radius: 4px;
  }
`;

export const ButtonToggleFullScreenNav = styled("button")`
  border: none;
  background-color: inherit;
`;
