import * as actionTypes from "../Types/";

export interface CountryProps {
  id: number;
  name: string;
  alpha2: string;
  alpha3: string;
}

interface State {
  data: CountryProps[];
  lang: string;
}

export const initialState: State = {
  data: [],
  lang: "",
};

const Countries = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.COUNTRIES_SET_COUNTRIES:
      return {
        ...state,
        data: action.data,
        lang: action.lang,
      } as State;
    default:
      return state;
  }
};

export default Countries;
