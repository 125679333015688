import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import axios from "axios";
import Amplify from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import { setTranslations, setLocale } from "react-i18nify";
import translations from "./translations";
import environment from "./Environment/environment";

setTranslations(translations);
let navigatorLanguage = window.navigator.language;
let lang = Object.keys(translations).includes(navigatorLanguage.split("-")[0])
  ? navigatorLanguage.split("-")[0]
  : "en";
setLocale(lang);

Amplify.configure({
  Auth: environment.getCognitoConfigs(),
});

// Axios configs
axios.defaults.baseURL = environment.backend;

axios.interceptors.request.use(async function (config) {
  const user = await Auth.currentSession();
  const idToken = user.getIdToken().getJwtToken();
  config.headers.Authorization = `Bearer ${idToken}`;
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      await Auth.signOut();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

ReactDOM.render(<App />, document.getElementById("root"));
