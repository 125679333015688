/**
 * Translations for Login screen with the following languages:
 * - English
 * - Portuguese
 *  */

export default {
  //English
  en: {
    LoginPage: {
      ChallengeComponent: {
        invalidPassword: "Invalid password",
        notEqualPassword: "Passwords are not the same",
        chooseNewPassword: "Choose a new password",
        newPassword: "New password",
        confirmNewPassword: "Confirm new password",
        updatePasswordAndLogin: "UPDATE PASSWORD AND ENTER",
        backToLoginPage: "Back to login page",
      },
      ForgotPasswordComponent: {
        invalidEmail: "Invalid e-mail",
        invalidPassword: "Invalid password",
        notEqualPassword: "Passwords are not the same",
        codeSent: "Enter the code sent in your email",
        code: "Code",
        newPassword: "New password",
        confirmNewPassword: "Confirm new password",
        saveNewPassword: "SAVE NEW PASSWORD",
        emailToRecoverPassword: "Enter your email to retrieve your password",
        email: "E-mail",
        recoverPassword: "RECOVER PASSWORD",
        backToLogin: "Back to login",
        expiredCodeException: "Expired code",
        limitExceededException:
          "Attempt limit exceeded, please try after some time",
        codeMismatchException: "Invalid code",
      },
      LoginComponent: {
        inputYourData: "Enter your e-mail and password to login",
        userMail: "E-mail",
        password: "Password",
        invalidUsermailOrPassword: "Invalid e-mail and / or password",
        login: "LOG IN",
        forgotMyPassword: "I forgot my password",
      },
    },
  },

  // Portuguese
  pt: {
    LoginPage: {
      ChallengeComponent: {
        invalidPassword: "Senha inválida",
        notEqualPassword: "Senhas não são iguais",
        chooseNewPassword: "Escolha uma nova senha",
        newPassword: "Nova senha",
        confirmNewPassword: "Confirmar nova senha",
        updatePasswordAndLogin: "ATUALIZAR SENHA E ENTRAR",
        backToLoginPage: "Voltar para a página inicial",
      },
      ForgotPasswordComponent: {
        invalidEmail: "Email inválido",
        invalidPassword: "Senha inválida",
        notEqualPassword: "Senha não são iguais",
        codeSent: "Digite o código enviado no seu email",
        code: "Código",
        newPassword: "Nova senha",
        confirmNewPassword: "Confirmar nova senha",
        saveNewPassword: "SALVAR NOVA SENHA",
        emailToRecoverPassword: "Digite seu e-mail para recuperar sua senha",
        email: "E-mail",
        recoverPassword: "RECUPERAR SENHA",
        backToLogin: "Voltar para fazer login",
        expiredCodeException: "O código expirou",
        limitExceededException: "Limite de tentativas excedido",
        codeMismatchException: "Código inválido",
      },
      LoginComponent: {
        inputYourData: "Digite seus dados para efetuar o login",
        userMail: "E-mail",
        password: "Senha",
        invalidUsermailOrPassword: "E-mail e/ou senha inválidos",
        login: "ENTRAR",
        forgotMyPassword: "Esqueci minha senha",
      },
    },
  },
};
