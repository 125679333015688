import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../Redux/Actions";
import { ScreensToShow } from "../../Redux/Reducer/LoginWidget";

import {
  FormLogin,
  FormHeader,
  MarginTopDiv,
  LoginActionBtn as LoginButton,
} from "../../Page/LoginPage_style";

import {
  Input,
  Label,
  InputGroup,
  ClickableEyeIcon,
  SpanLinkStyle as ForgotPass,
  InputTextError,
} from "../../../../Global/StyleGuide";
import Loading from "../LoginLoading/LoginLoading";
import { verifyEmail, verifyPassword } from "../../../../Utils";
import { useSelector } from "../../../../Redux";
import { t } from "react-i18nify";

interface LoginComponentProps {}

const LoginComponent: React.FC<LoginComponentProps> = () => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState<string>("");
  let { isAuthenticating: isLoading, email, hasError, error } = useSelector(
    (state) => state.Login.LoginWidget
  );
  const [readOnly, setReadOnly] = useState(true);

  const forgotPassword = () => {
    dispatch(actions.setScreenToShow(ScreensToShow.FORGOT_PASSWORD_SCREEN));
  };

  const handleLogin = async (event: any) => {
    event.preventDefault();
    dispatch(actions.authenticate(email, password));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setReadOnly(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <FormLogin onSubmit={handleLogin}>
      {!isLoading ? (
        <>
          <FormHeader>{t("LoginPage.LoginComponent.inputYourData")}</FormHeader>
          <MarginTopDiv value="3vh">
            <InputGroup width="316px" noMargin>
              <Input
                id="userMail"
                name="userMail"
                readOnly={readOnly}
                placeholder={t("LoginPage.LoginComponent.userMail")}
                value={email}
                onChange={(e: any) => {
                  dispatch(actions.setEmail(e.target.value));
                }}
                type="text"
              />
              <Label htmlFor="userMail">
                {t("LoginPage.LoginComponent.userMail")}
              </Label>
            </InputGroup>
          </MarginTopDiv>
          <MarginTopDiv value="3vh">
            <InputGroup width="316px" noMargin>
              <Input
                id="password"
                readOnly={readOnly}
                name="password"
                placeholder={t("LoginPage.LoginComponent.password")}
                value={password}
                onChange={(e: any) => {
                  setPassword(e.target.value);
                }}
                type={showPassword ? "text" : "password"}
              />
              <Label htmlFor="password">
                {t("LoginPage.LoginComponent.password")}
              </Label>
              <ClickableEyeIcon
                show={!showPassword}
                onClick={toggleShowPassword}
              />
              {hasError && (
                <InputTextError>
                  {(error === "UserNotFoundException" ||
                    error === "NotAuthorizedException") &&
                    t("LoginPage.LoginComponent.invalidUsermailOrPassword")}
                </InputTextError>
              )}
            </InputGroup>
          </MarginTopDiv>
          <MarginTopDiv value="6vh">
            <LoginButton
              type="submit"
              buttonStyle="filled"
              disabled={
                !email ||
                !password ||
                !verifyPassword(password) ||
                !verifyEmail(email)
              }
            >
              {t("LoginPage.LoginComponent.login")}
            </LoginButton>
          </MarginTopDiv>
          <MarginTopDiv value="6vh">
            <ForgotPass onClick={() => forgotPassword()}>
              {t("LoginPage.LoginComponent.forgotMyPassword")}
            </ForgotPass>
          </MarginTopDiv>
        </>
      ) : (
        <Loading size={315} />
      )}
    </FormLogin>
  );
};

export default LoginComponent;
