import { css } from "styled-components";
import { Colors } from "./colors";
import { GraphicElements } from "./graphicElements";

export const MainFilledButton = css`
  transition: 0.2s;
  background: ${({ variant }) =>
    variant === "secondary"
      ? Colors.Brand.BrandBluePrimary
      : variant === "disabled"
      ? Colors.Neutral.LightGray
      : variant === "error"
      ? Colors.Status.FailureStatus
      : Colors.Brand.BrandPrimary};

  color: ${Colors.Neutral.PureWhite};
  border: none;
  cursor: pointer;
  outline: none;

  &:disabled {
    color: ${Colors.Neutral.StandardGray};
    background: ${Colors.Neutral.LightGray};
    cursor: default;
  }

  &:active {
    background: ${Colors.Neutral.StandardGray};
  }
`;

export const MainOutlinedButton = css`
  ${MainFilledButton}
  ${GraphicElements.BorderWidthStyle.SolidSmall}
  border-color: ${Colors.Neutral.LightGray};
  background: none;
  color: ${({ variant }) =>
    variant === "secondary"
      ? Colors.Brand.BrandBluePrimary
      : variant === "error"
      ? Colors.Status.FailureStatus
      : Colors.Brand.BrandPrimary};

  &:disabled {
    border-color: ${Colors.Neutral.StandardGray};
    background: none;
  }
`;
