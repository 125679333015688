export const BUCKETS_SET_BUCKETS = "BUCKETS_SET_BUCKETS";
export const AWS_BUCKETS_SET_AWS_BUCKETS = "AWS_BUCKETS_SET_AWS_BUCKETS";
export const NEW_BUCKET_SET_AWSBUCKET = "NEW_BUCKET_SET_AWSBUCKET";
export const NEW_BUCKET_SET_PROJECT = "NEW_BUCKET_SET_PROJECT";
export const NEW_BUCKET_SET_STATUS = "NEW_BUCKET_SET_STATUS";
export const NEW_BUCKET_RESET = "NEW_BUCKET_RESET";
export const BUCKETS_SET_LOADER = "BUCKETS_SET_LOADER";
export const BUCKETS_SET_SHOWALL = "BUCKETS_SET_SHOWALL";
export const BUCKET_RESET = "BUCKET_RESET";
export const BUCKET_SET_BUCKET = "BUCKET_SET_BUCKET";
export const BUCKETS_SET_NOTIFICATION = "BUCKETS_SET_NOTIFICATION";
export const BUCKETS_RESET_NOTIFICATION = "BUCKETS_RESET_NOTIFICATION";
