/**
 * Translations for Navigation components with the following languages:
 * - English
 * - Portuguese
 *  */

export default {
  //English
  en: {
    NavigationPage: {
      NavBar: {
        logout: "Logout",
        GetMenuTitles: {
          dashboard: "Dashboard",
          installer: "Installer",
          farms: "Farms",
          buckets: "Buckets",
          users: "Users",
        },
      },
    },
  },

  // Portuguese
  pt: {
    NavigationPage: {
      NavBar: {
        logout: "Sair",
        GetMenuTitles: {
          dashboard: "Dashboard",
          installer: "Instalador",
          farms: "Fazendas",
          buckets: "Buckets",
          users: "Usuários",
        },
      },
    },
  },
};
