// The next line initialize a constant who contain the url to the wanted environment
const backend = "https://la08lg6loi.execute-api.us-east-1.amazonaws.com/prod/";
// The next line initialize an object who contain the configs to access the AWS Cognito
const awsConfig = {
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_0l9UfQVpP",
    APP_CLIENT_ID: "espg5ksbnn4cdm3ff2q8t81e6",
    IDENTITY_POOL_ID: "us-east-1:db517a5e-3afc-4b25-b172-5bb06a21875c",
  },
}

const getCognitoConfigs = () => {
  return {
    mandatorySignIn: true,
    region: awsConfig.cognito.REGION,
    userPoolId: awsConfig.cognito.USER_POOL_ID,
    identityPoolId: awsConfig.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: awsConfig.cognito.APP_CLIENT_ID,
  };
};

export default {
  backend,
  getCognitoConfigs,
};
