/**
 * Translations for Dashboard menu with the following languages:
 * - English
 * - Portuguese
 *  */

export default {
  //English
  en: {
    DashboardPage: {
      title: "Dashboard",
      refresh: "REFRESH",
      NewFarmsTable: {
        TableHead: {
          name: "Name",
          country: "Country",
          bucket: "Bucket",
          module: "Module",
          champion: "Champion",
          intalation: "Instalation",
        },
        TableBody: {
          noBucketProject: "Deleted Bucket",
          instalationFormat: "MM-DD-YYYY HH:mm",
        },
        Card: {
          title: "New Farms",
          NoData: {
            firstLine: "There is no authorization request.",
            secondLine: "When there are orders, they will appear here.",
          },
        },
      },
      Notification: {
        PendingFarmsError: {
          title: "Error",
          message: "Error while getting new farms",
        },
        ChangingPendingFarmStatusSuccess: {
          title: "Success",
          message: "Status changed successfully",
        },
        ChangingPendingFarmStatusError: {
          title: "Error",
          message: "Error while changing status",
        },
      },
    },
  },

  // Portuguese
  pt: {
    DashboardPage: {
      title: "Dashboard",
      refresh: "ATUALIZAR",
      NewFarmsTable: {
        TableHead: {
          name: "Nome",
          country: "País",
          bucket: "Bucket",
          module: "Módulo",
          champion: "Champion",
          intalation: "Instalação",
        },
        TableBody: {
          noBucketProject: "Bucket Deletado",
          instalationFormat: "DD/MM/YYYY HH:mm",
        },
        Card: {
          title: "Novas Fazendas",
          NoData: {
            firstLine: "Não existe nenhum pedido de autorização.",
            secondLine: "Quando existirem pedidos, eles aparecerão aqui.",
          },
        },
      },
      Notification: {
        PendingFarmsError: {
          title: "Erro",
          message: "Erro ao buscar as fazendas pendentes",
        },
        ChangingPendingFarmStatusSuccess: {
          title: "Sucesso",
          message: "Sucesso ao mudar o status",
        },
        ChangingPendingFarmStatusError: {
          title: "Erro",
          message: "Erro ao mudar o status",
        },
      },
    },
  },
};
