import styled from "styled-components";
import { GraphicElements } from "./graphicElements";
import { Fonts } from "./typography";
import { Colors } from "./colors";
import { Link } from "react-router-dom";

import { MainFilledButton, MainOutlinedButton } from "./buttons_css";

interface MainButtonProps {
  buttonStyle: string;
  variant?: string;
}

export const MainButtonXSmall = styled("button")<MainButtonProps>`
  ${({ buttonStyle }) =>
    buttonStyle === "filled" ? MainFilledButton : MainOutlinedButton}
  ${Fonts.Buttons.BT2}
  ${GraphicElements.BorderRadius.XLarge}
  display: block;
  width: 80px;
  height: 24px;
`;

export const MainButtonSmall = styled("button")<MainButtonProps>`
  ${({ buttonStyle }) =>
    buttonStyle === "filled" ? MainFilledButton : MainOutlinedButton}
  ${Fonts.Buttons.BT2}
  ${GraphicElements.BorderRadius.XLarge}
  display: block;
  width: 120px;
  height: 32px;
`;

export const MainButtonMedium = styled("button")<MainButtonProps>`
  ${({ buttonStyle }) =>
    buttonStyle === "filled" ? MainFilledButton : MainOutlinedButton}
  ${Fonts.Buttons.BT1}
  ${GraphicElements.BorderRadius.XLarge}
  display: block;
  width: 160px;
  height: 40px;
`;

export const MainButtonLarge = styled("button")<MainButtonProps>`
  ${({ buttonStyle }) =>
    buttonStyle === "filled" ? MainFilledButton : MainOutlinedButton}
  ${Fonts.Buttons.BT1}
  ${GraphicElements.BorderRadius.XLarge}
  display: block;
  width: 206px;
  height: 48px;
`;

export const MainButtonXLarge = styled("button")<MainButtonProps>`
  ${({ buttonStyle }) =>
    buttonStyle === "filled" ? MainFilledButton : MainOutlinedButton}
  ${Fonts.Buttons.BT1}
  ${GraphicElements.BorderRadius.XLarge}
  display: block;
  width: 240px;
  height: 56px;
`;

export const SpanLinkStyle = styled.span`
  ${Fonts.Body.B2}
  color: ${Colors.Brand.BrandBluePrimary} !important;
  cursor: pointer;
`;

export const LinkStyle = styled(Link)`
  ${Fonts.Body.B2}
  color: ${Colors.Brand.BrandBluePrimary};
  cursor: pointer;
  text-decoration: none;
`;
