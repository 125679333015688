import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../Redux/Actions";
import { useSelector } from "../../../../Redux";
import { ScreensToShow } from "../../Redux/Reducer/LoginWidget";
import {
  FormLogin,
  FormHeader,
  MarginTopDiv,
  LoginActionBtn as UpdatePasswordBtn,
} from "../../Page/LoginPage_style";
import {
  Input,
  Label,
  InputGroup,
  InputTextError,
  ClickableEyeIcon,
} from "../../../../Global/StyleGuide";
import { SpanLinkStyle as BackToLogin } from "../../../../Global/StyleGuide";
import { verifyEmail, verifyPassword } from "../../../../Utils";
import { t } from "react-i18nify";

interface ForgotPasswordComponentProps {}

const ForgotPasswordComponent: React.FC<ForgotPasswordComponentProps> = () => {
  const dispatch = useDispatch();
  let { hasError, error, isWaitingCode, email } = useSelector(
    (state) => state.Login.LoginWidget
  );

  const [readOnly, setReadOnly] = useState(true);
  const [code, setCode] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [completePassword, setCompletePassword] = useState("");
  const [confirmCompletePassword, setConfirmCompletePassword] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");

  useEffect(() => {
    const timer = setTimeout(
      () => {
        setReadOnly(false);
      },
      isWaitingCode && !hasError ? 2000 : 1000
    );
    return () => clearTimeout(timer);
  }, [isWaitingCode, hasError]);

  const validadeEmail = () => {
    if (verifyEmail(email)) {
      setErrorEmail("");
    } else {
      setErrorEmail(t("LoginPage.ForgotPasswordComponent.invalidEmail"));
    }
  };

  const handleSendMailPassword = async (event: any) => {
    event.preventDefault();
    dispatch(actions.initialResetPassword(email));
    setReadOnly(true);
  };

  const handleSendCodePassword = async (event: any) => {
    event.preventDefault();
    await dispatch(actions.finalResetPassword(email, code, completePassword));
    dispatch(actions.authenticate(email, completePassword));
  };

  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handlePasswordValidation = (event: any) => {
    const currentPassword = event.target.value;
    if (verifyPassword(currentPassword)) {
      setErrorPassword("");

      if (confirmCompletePassword) {
        handlePasswordMatch(currentPassword, confirmCompletePassword);
      }
    } else {
      setErrorPassword(t("LoginPage.ForgotPasswordComponent.invalidPassword"));
    }
  };

  const handlePasswordMatch = (password: string, confirmPassword: string) => {
    if (confirmPassword.length < password.length) {
      return;
    }
    if (confirmPassword !== "" && password !== confirmPassword) {
      setErrorConfirmPassword(
        t("LoginPage.ForgotPasswordComponent.notEqualPassword")
      );
    } else {
      setErrorConfirmPassword("");
    }
  };

  const backToLogin = () => {
    dispatch(actions.setScreenToShow(ScreensToShow.LOGIN_SCREEN));
  };

  const showedForm =
    !isWaitingCode && !hasError ? (
      <>
        <MarginTopDiv value="6vh">
          <FormHeader>
            {t("LoginPage.ForgotPasswordComponent.emailToRecoverPassword")}
          </FormHeader>
          <MarginTopDiv value="3vh">
            <InputGroup width="316px" noMargin>
              <Input
                readOnly={readOnly}
                id="userMail"
                name="userMail"
                placeholder={t("LoginPage.ForgotPasswordComponent.email")}
                value={email}
                onChange={(e: any) => {
                  dispatch(actions.setEmail(e.target.value));
                  setErrorEmail("");
                }}
                onBlur={validadeEmail}
                type="email"
              />
              <Label htmlFor="userMail">
                {t("LoginPage.ForgotPasswordComponent.email")}
              </Label>
              <InputTextError>{errorEmail}</InputTextError>
            </InputGroup>
          </MarginTopDiv>
          <MarginTopDiv value="6vh">
            <UpdatePasswordBtn
              type="submit"
              buttonStyle="filled"
              disabled={email === "" || !verifyEmail(email)}
            >
              {t("LoginPage.ForgotPasswordComponent.recoverPassword")}
            </UpdatePasswordBtn>
          </MarginTopDiv>
        </MarginTopDiv>
      </>
    ) : (
      <>
        <FormHeader>
          {t("LoginPage.ForgotPasswordComponent.codeSent")}
        </FormHeader>
        <MarginTopDiv value="3vh">
          <InputGroup width="316px" noMargin>
            <Input
              id="code"
              readOnly={readOnly}
              name="code"
              placeholder={t("LoginPage.ForgotPasswordComponent.code")}
              value={code}
              onChange={(e: any) => {
                setCode(e.target.value);
              }}
              type="text"
            />
            <Label htmlFor="code">
              {t("LoginPage.ForgotPasswordComponent.code")}
            </Label>
            {hasError && (
              <InputTextError>
                {error === "ExpiredCodeException"
                  ? t("LoginPage.ForgotPasswordComponent.expiredCodeException")
                  : error === "LimitExceededException"
                  ? t(
                      "LoginPage.ForgotPasswordComponent.limitExceededException"
                    )
                  : error === "CodeMismatchException"
                  ? t("LoginPage.ForgotPasswordComponent.codeMismatchException")
                  : null}
              </InputTextError>
            )}
          </InputGroup>
        </MarginTopDiv>
        <MarginTopDiv value="3vh">
          <InputGroup width="316px" noMargin>
            <Input
              id="newPass"
              readOnly={readOnly}
              name="newPass"
              placeholder={t("LoginPage.ForgotPasswordComponent.newPassword")}
              value={completePassword}
              onChange={(e: any) => {
                setCompletePassword(e.target.value);
                if (confirmCompletePassword) {
                  handlePasswordValidation(e);
                }
              }}
              onBlur={handlePasswordValidation}
              type={showPassword ? "text" : "password"}
            />
            <Label htmlFor="newPass">
              {t("LoginPage.ForgotPasswordComponent.newPassword")}
            </Label>
            <ClickableEyeIcon
              show={!showPassword}
              onClick={toggleShowPassword}
            />
            <InputTextError>{errorPassword}</InputTextError>
          </InputGroup>
        </MarginTopDiv>
        <MarginTopDiv value="3vh">
          <InputGroup width="316px" noMargin>
            <Input
              id="confirmNewPass"
              readOnly={readOnly}
              name="confirmNewPass"
              placeholder={t(
                "LoginPage.ForgotPasswordComponent.confirmNewPassword"
              )}
              value={confirmCompletePassword}
              onChange={(e: any) => {
                handlePasswordMatch(completePassword, e.target.value);
                setConfirmCompletePassword(e.target.value);
              }}
              onBlur={() =>
                handlePasswordMatch(completePassword, confirmCompletePassword)
              }
              type={showConfirmPassword ? "text" : "password"}
            />
            <Label htmlFor="confirmNewPass">
              {t("LoginPage.ForgotPasswordComponent.confirmNewPassword")}
            </Label>
            <ClickableEyeIcon
              show={!showConfirmPassword}
              onClick={toggleShowConfirmPassword}
            />
            <InputTextError>{errorConfirmPassword}</InputTextError>
          </InputGroup>
        </MarginTopDiv>
        <MarginTopDiv value="6vh">
          <UpdatePasswordBtn
            type="submit"
            buttonStyle="filled"
            disabled={
              code === "" ||
              completePassword === "" ||
              confirmCompletePassword === "" ||
              completePassword !== confirmCompletePassword ||
              completePassword.length < 8 ||
              confirmCompletePassword.length < 8 ||
              !verifyPassword(completePassword) ||
              !verifyPassword(confirmCompletePassword)
            }
          >
            {t("LoginPage.ForgotPasswordComponent.saveNewPassword")}
          </UpdatePasswordBtn>
        </MarginTopDiv>
      </>
    );

  return (
    <FormLogin
      onSubmit={
        !isWaitingCode && !hasError
          ? handleSendMailPassword
          : handleSendCodePassword
      }
    >
      {showedForm}

      <MarginTopDiv value="6vh">
        <BackToLogin onClick={() => backToLogin()}>
          {t("LoginPage.ForgotPasswordComponent.backToLogin")}
        </BackToLogin>
      </MarginTopDiv>
    </FormLogin>
  );
};

export default ForgotPasswordComponent;
